import React from 'react';
import copy from '../../assets/images/copy.svg'
import Tooltip from '@mui/material/Tooltip';

import './css/Button.css'

const Button = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  const copyData = () => {
    let textField = document.createElement('textarea')
    textField.innerText = data;
    document.body.appendChild(textField)
    textField.select();
    document.execCommand('copy');
    textField.remove()
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000)
  }

  return (
    < div className='iban' >
      {(data.length > 20) ? <p>IBAN: {data}</p> : <p>{data}</p>}
      <Tooltip open={open} placement="bottom" title="COPIED TO BUFFER">
        <button onClick={copyData}><img src={copy} alt="copy" /></button>
      </Tooltip>
    </div >
  )
}

export default Button;


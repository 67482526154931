import Button from '../Button/Button';

import './css/Card.css'

const Card = ({ currency }) => {
  const { title, beneficiary, iban, code, cardNumber } = currency;
  return (
    <div className="card">
      <h1>{title}</h1>
      {beneficiary ? <p>{beneficiary}</p> : null}
      {code ? <p>{code}</p> : null}
      {cardNumber ? <Button data={cardNumber} /> : null}
      <Button data={iban} />
    </div>
  );
}

export default Card;
import facebook from '../../assets/images/facebook.png'

import './css/Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>Charity Foundation "Kropyva" 2022</p>
      <div className='facebook'>
        <a href="https://www.facebook.com/groups/456651606343598" target='blank_'><img src={facebook} alt="facebook" /></a>
        <a href="https://www.facebook.com/groups/456651606343598" target='blank_' className='foot-link'>Foundation page</a>
      </div>
      <a href="https://mihalishcher.github.io/My-resume/" target='blank_' className='foot-link'>Made by Mihail Shcherbakov</a>
    </footer>
  );

}

export default Footer;
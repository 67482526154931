import BankInfo from '../../components/BankInfo/BankInfo';
import Card from '../../components/Card/Card'
import currency from '../../components/Card/requzits';

import './css/Home.css'

const Home = () => {
  return (
    <div className='home'>
      <div className='monobank'>
        <a href="https://send.monobank.ua/jar/36oeg9HcKM" target="blank_">monobank</a>
      </div>
      <Card currency={currency.privatBank} />
      <Card currency={currency.paypal} />
      <Card currency={currency.usd} />
      <Card currency={currency.euro} />
      <BankInfo />
    </div>
  );

}

export default Home;
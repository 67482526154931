import logo from '../../assets/images/kropyva_en.jpg';

import './css/Header.css'

const Header = () => {
  return (
    <header>
      <img src={logo} className="logo" alt="logo" />
      <h1 className='title'>Charity Foundation "Kropyva"</h1>
    </header>
  );

};

export default Header;
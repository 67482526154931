import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';

import './styles/App.css';
import Home from './pages/Home/Home';

const App = () => {
  return (
    <div className='App root'>
      <Header />
      <Home />
      <Footer />
    </div>
  );

};

export default App;

import './css/BankInfo.css'

const BankInfo = () => {
  return (
    <div className="bank">
      <h1>Information about bank</h1>
      <p>Beneficiary Bank Address: Ukraine, 01135, m.Kyiv, vul.Zolotoustivs'ka, 47-49, kv.152</p>
      <p>Beneficiary bank: JSC CB PRIVATBANK</p>
      <p>Bank Address: PRIVATBANK, 1D HRUSHEVSKOGO STR., KYIV, 01001, UKRAINE</p>
      <p>SWIFT CODE: PBANUA2X</p>
    </div>
  );
}

export default BankInfo;
const currency = {
  usd: {
    title: 'SWIFT in US dollars (USD)',
    beneficiary: 'Beneficiary: CO CF Kropyva',
    iban: 'UA 5730 5299 0000 0260 0304 5108 053'
  },
  euro: {
    title: 'SWIFT in euros (EUR)',
    beneficiary: 'Beneficiary: CO CF Kropyva',
    iban: 'UA 9230 5299 0000 0260 0103 5105 530'
  },
  paypal: {
    title: 'PayPal',
    iban: 'bosjak20@gmail.com'
  },
  privatBank: {
    title: 'Privat Bank',
    beneficiary: 'Отримувач: БО «БФ «Кропива»»',
    code: 'ЄДРПОУ: 44702848',
    cardNumber: '4246 0010 0267 1299',
    iban: 'UA 1030 5299 0000 0260 0704 5113 516'
  }


}

export default currency;

